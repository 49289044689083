/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* My color palette:
#0F172A blueGray-900
#1E293B blueGray-800
#334155 blueGray-700
#475569 blueGray-600
#155E75 cyan-800
#0F766E teal-700
#E7E5E4 warmGray-200
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gradient-to-r from-blueGray-800 to-blueGray-900 text-warmGray-200; /*  bg-stardust-pattern animate-linear-infinite; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6 {
  @apply py-2;
}

h1 {
  @apply font-extrabold text-2xl;
}

h2 {
  @apply font-extrabold text-xl;
}

h3, h4 {
  @apply font-medium text-lg;
}

h5, h6 {
  @apply font-medium text-base;
}

a {
  word-wrap:break-word;
  @apply text-cyan-500 hover:underline hover:text-blueGray-200 text-base;
}

p {
  @apply text-base;
}

.px-general {
  @apply px-4 sm:px-8 md:px-12;
}

.py-general {
  @apply py-4 sm:py-8 md:py-12;
}