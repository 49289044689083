/* My color palette:
#1E293B blueGray-800
#155E75 cyan-800
#0F766E teal-700
#E7E5E4 warmGray-200
*/

.form-input {
  @apply w-full 
   bg-white 
   disabled:bg-blueGray-600 
   rounded 
    focus:ring-2 
    text-base 
    outline-none
   disabled:text-warmGray-200
   text-warmGray-700
    py-1 px-3 
    leading-8 
    transition-colors duration-200 ease-in-out;
}

/* width */
.form-input::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.form-input::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.form-input::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.form-input::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill:disabled,
textarea:-webkit-autofill:disabled,
select:-webkit-autofill:disabled {
  @apply w-full rounded border border-blueGray-300 text-base outline-none py-1 px-3 leading-8;
  -webkit-text-fill-color: #e7e5e4;
  -webkit-box-shadow: 0 0 0px 1000px rgba(71, 85, 105, var(--tw-bg-opacity)) inset;
}
