.item-ICanDevelop {
  @apply inline-block font-medium tracking-wider p-2 mr-2 mb-2 mt-2 rounded-lg border-2 bg-blueGray-800 bg-opacity-80;
}

@keyframes linear-translate-ICanDevelop {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
