@keyframes translateX-stars {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.star-layer {
  will-change: transform;
}
