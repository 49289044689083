/* My color palette:
#1E293B blueGray-800
#155E75 cyan-800
#0F766E teal-700
#E7E5E4 warmGray-200
*/


.footer-links {
    @apply rounded text-warmGray-300 hover:text-warmGray-50 focus:text-warmGray-50;
}