@keyframes linear-translate-pictures {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }

.heading {
  @apply text-2xl md:text-4xl py-0 pb-6;
}