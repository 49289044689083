/* My color palette:
#1E293B blueGray-800
#155E75 cyan-800
#0F766E teal-700
#E7E5E4 warmGray-200
*/

.logo {
    @apply h-14 w-auto;
}

.links {
    @apply mt-1 px-2 py-1 text-warmGray-200 font-semibold rounded sm:ml-2;
}