@keyframes linear-rotation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes move-left-to-right {
  0% {
    transform: translateX(-50vw);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(50vw);
  }
  100% {
    transform: translateX(50vw);
  }
}

@keyframes fade {
  0%{
    opacity: 0;
  }
   5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
